import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import widont from "widont"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Background } from "react-imgix"

import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import Image from "../../../components/image"
import VideoInline from "../../../components/videoInline"
import useViewportState from "../../../hooks/useViewportState.js"
import { clamp, remap } from "../../../utils.js"

import styles from "./alternate-economies.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
}

//
// Image Fader
//   - Fades between two images on-scroll
//
const Fader = ({ image1, image2 }) => {
  const ref = useRef(null)
  const imageRef = useRef(null)

  useViewportState(
    scrollTop => {
      let node = ref.current
      if (node !== null && node.offsetHeight !== 0) {
        const elementOffsetTop = node.offsetTop
        const elementHeight = node.getBoundingClientRect().height
        const halfElementHeight = elementHeight / 2
        const winHeight = window !== "undefined" ? window.innerHeight : 0

        const low1 =
          elementOffsetTop - winHeight + winHeight * 0.25 + halfElementHeight
        const high1 = elementOffsetTop - winHeight * 0.25 + halfElementHeight
        const opacity = clamp(0, 1, remap(low1, high1, 0, 1, scrollTop))

        if (imageRef.current !== null) imageRef.current.style.opacity = opacity
      }
    },
    state => state.scrollTop
  )

  return (
    <div className={styles.fader}>
      <div ref={ref} className={styles.faderInner}>
        <div>{image1}</div>
        <div ref={imageRef} className={styles.faderImage2}>
          {image2}
        </div>
      </div>
    </div>
  )
}

//
// Image Sidebar
//   - Fades between three images on a timer
//
const ImageSidebar = ({ image1, image2, image3 }) => {
  const DURATION = 6 * 1000
  const image1Ref = useRef(null)
  const image2Ref = useRef(null)
  const image3Ref = useRef(null)
  const imageRefs = [image1Ref, image2Ref, image3Ref]

  useEffect(() => {
    let request
    let tick = 0
    // Start with the first image visible
    if (image1Ref.current !== null) {
      image1Ref.current.classList.add(styles.isVisible)
    }

    let interval = setInterval(() => {
      tick += 1
      let bgImgIndex = (tick - 1) % 3
      let fgImgIndex = tick % 3
      let bgImg = imageRefs[bgImgIndex].current
      let fgImg = imageRefs[fgImgIndex].current

      if (fgImg !== null && bgImg !== null) {
        bgImg.classList.remove(styles.isVisible)
        fgImg.classList.add(styles.isVisible)
      }
    }, DURATION)

    return () => {
      clearInterval(interval)
      cancelAnimationFrame(request)
    }
  }, [DURATION, imageRefs])

  return (
    <div>
      <div ref={image1Ref}>{image1}</div>
      <div ref={image2Ref}>{image2}</div>
      <div ref={image3Ref}>{image3}</div>
    </div>
  )
}

//
// Section
//   - Section contaienr, handles intersection observer and sets fixed nav
//     active state for mobile navigation
//
const Section = props => {
  const sectionRef = useRef(null)
  const options = {
    rootMargin: "-20% 0% -80% 0%",
  }

  useEffect(() => {
    const node = sectionRef.current

    const observer = new IntersectionObserver(function callback(entries) {
      entries.forEach(entry => {
        const link =
          typeof document !== "undefined"
            ? document.querySelector(`a.js-nav-link[href="#${node.id}"]`)
            : null

        if (entry.isIntersecting) {
          link.classList.add(styles.isActive)
        } else {
          link.classList.remove(styles.isActive)
        }
      })
    }, options)

    observer.observe(node)

    return () => {
      observer.disconnect()
    }
  })

  return (
    <div {...props} ref={sectionRef}>
      {props.children}
    </div>
  )
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta
  // const headerImage = data.craft.headerImage
  const headerVideo = data.craft.headerVideo
  const endImage = data.craft.endImage
  const intro1Image = data.craft.intro1Image
  const intro2Image = data.craft.intro2Image
  const intro3Image = data.craft.intro3Image
  const intro4Image = data.craft.intro4Image
  const longevity1Image = data.craft.longevity1Image
  const longevity2Image = data.craft.longevity2Image
  const longevity3Image = data.craft.longevity3Image
  const solidarity1Image = data.craft.solidarity1Image
  const solidarity2Image = data.craft.solidarity2Image
  const solidarity3Image = data.craft.solidarity3Image
  const wellbeing1Image = data.craft.wellbeing1Image
  const wellbeing2Image = data.craft.wellbeing2Image
  const wellbeing3Image = data.craft.wellbeing3Image

  // Custom Header, recreate parts of articleHeader.js
  const issueTitle = article.issue[0].title
  const pad = num => {
    return num < 10 ? "0" + num.toString() : num.toString()
  }
  const issueNumber = pad(
    data.craft.issues.findIndex(issue => issue.id === article.issue[0].id) + 1
  )

  return (
    <Layout seo={seo} mode="athena" theme={theme} shyHeader={false}>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <header className="wrapper wrapper--full-bleed">
            <div className="text-center">
              <div className={styles.header}>
                <div className={styles.issue}>
                  <p className="a-h-5">
                    Issue {issueNumber} / {issueTitle}
                  </p>
                </div>
                <div className={styles.title}>
                  <h1>{article.title}</h1>
                </div>
                <div className={styles.subtitle} itemProp="subheading">
                  <p className="a-h-5">{article.articleSubTitle}</p>
                </div>
              </div>
            </div>
            <div className={styles.customHeader}>
              <h1 className={styles.customHeaderText} aria-hidden={true}>
                <span className={styles.customHeaderTextTop}>Alternate</span>
                <span className={styles.customHeaderTextBottom}>Economies</span>
              </h1>
              <div className={styles.customHeaderMedia}>
                <VideoInline
                  src={headerVideo.url}
                  type={headerVideo.mimeType}
                />
              </div>
            </div>
          </header>

          <div>
            <div className={styles.copySection}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        Lately, when we hear the word "economic," it's often
                        followed by the word "crisis."
                      </p>
                      <p>
                        We’re all feeling the forces of the economy: It
                        dominates the headlines, provokes either fear or hope,
                        and, quite often, stokes a sense of powerlessness. It
                        feels like the economy is simply something that happens
                        to us—that it’s just a gargantuan, incomprehensible
                        system that we have to live within.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="columns is-tablet is-centered no-vertical-gap">
                  <div className="column is-10-tablet is-9-desktop">
                    <p className={styles.emphasis}>
                      {widont(
                        `It makes us ask—is this something I can live through? How can I manage the shit‑sandwich I’ve been served?`
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        This isn’t a piece about hating on the invisible hand of
                        capitalism—though there is certainly much wrong with it,
                        especially as it's currently defined. This isn't about
                        gig economies, microeconomics or even voodoo economics.
                        This is about creating a future where there are many
                        ways to exchange value that are not based on the
                        dollar—but instead upon individual choices and
                        connections. It's about how you interact and exchange
                        value with one another.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet">
                  <Fader
                    image1={
                      <Image
                        url={intro1Image.url}
                        sizes="(min-width: 1800px) 1800px, 100vw"
                      />
                    }
                    image2={
                      <Image
                        url={intro2Image.url}
                        sizes="(min-width: 1800px) 1800px, 100vw"
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.copySection}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <div className="user-content-athena">
                      <p>
                        There is a hard and fast truth about the
                        pandemic-stricken world: It’s giving us an unparalleled
                        opportunity to assess our ways of living, and to
                        question the very systems that act as the undercurrent
                        of our day-to-day motions. We’re examining what we can
                        control, and realizing what’s out of our hands. We’re
                        also thinking more about what we truly value.
                      </p>
                      <p>
                        The reality is that we can change more about our
                        day-to-day lives through our actions than we may
                        think—especially since we are (more or less) all having
                        an inflection moment together, at the same time.
                      </p>
                      <p>
                        This is true for the economies that we engage in. Maybe
                        not the “economy” in the traditional sense—the Fed and
                        the Treasury and corporate America will continue to
                        chugalug. But what if there were ways to participate in
                        capitalism as you need, while engaging in other
                        economies that you'd prefer? What if you could create
                        different economies based on your personal values? Well,
                        there are. We just haven't fully explored their
                        potential yet.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet">
                  <Fader
                    image1={
                      <Image
                        url={intro3Image.url}
                        sizes="(min-width: 1800px) 1800px, 100vw"
                      />
                    }
                    image2={
                      <Image
                        url={intro4Image.url}
                        sizes="(min-width: 1800px) 1800px, 100vw"
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.copySection}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <p className={`${styles.emphasis} ${styles.emphasisFirst}`}>
                      {widont(
                        `So what can we learn by exploring these value-based economies?`
                      )}
                    </p>
                    <div className="mb-8 user-content-athena">
                      <p>
                        Here’s how we’ve been thinking about it. We started with
                        3 values that likely every person has, then asked a
                        simple question: If this is the value I have, what is
                        tangibly representative of it? How does that value come
                        to life? How would I exchange it and how would I get
                        more of it?
                      </p>
                      <p>
                        We started with some simple ones—which, we might argue,
                        we need to value now more than ever:
                      </p>
                    </div>
                    <div className="mb-8">
                      <ul className={styles.buttonList}>
                        <li>
                          <AnchorLink
                            className={styles.button}
                            href="#wellbeing"
                          >
                            <span className={styles.buttonText}>
                              Well-being
                            </span>
                          </AnchorLink>
                        </li>
                        <li>
                          <AnchorLink
                            className={styles.button}
                            href="#longevity"
                          >
                            <span className={styles.buttonText}>Longevity</span>
                          </AnchorLink>
                        </li>
                        <li>
                          <AnchorLink
                            className={styles.button}
                            href="#solidarity"
                          >
                            <span className={styles.buttonText}>
                              Solidarity
                            </span>
                          </AnchorLink>
                        </li>
                      </ul>
                    </div>
                    <div className="user-content-athena">
                      <p>
                        For each, we worked through a very easy-to-understand
                        value exchange. And what you’ll quickly see is that
                        there are many secondary benefits that go beyond the
                        initial exchange; when you extend transactions outside
                        the realm of the dollar, your transactions become less,
                        well, transactional.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.sectionWrapper}>
              {/* <div className="wrapper wrapper--full-bleed">
                <Image
                  url={intro7Image.url}
                  sizes="(min-width: 1800px) 1800px, 100vw"
                />
              </div> */}
              <ul className={styles.buttonListFixed}>
                <li>
                  <AnchorLink
                    className={`${styles.button} ${styles.isActive} js-nav-link`}
                    href="#wellbeing"
                  >
                    <span className={styles.buttonText}>Well-being</span>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                    className={`${styles.button} js-nav-link`}
                    href="#longevity"
                  >
                    <span className={styles.buttonText}>Longevity</span>
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink
                    className={`${styles.button} js-nav-link`}
                    href="#solidarity"
                  >
                    <span className={styles.buttonText}>Solidarity</span>
                  </AnchorLink>
                </li>
              </ul>
              <div className="wrapper wrapper--full-bleed">
                <Section id="wellbeing" className={styles.section}>
                  <div className={styles.sectionImages}>
                    <ImageSidebar
                      image1={
                        <Background
                          className={styles.sectionImagesImage}
                          src={wellbeing1Image.url}
                        />
                      }
                      image2={
                        <Background
                          className={styles.sectionImagesImage}
                          src={wellbeing2Image.url}
                        />
                      }
                      image3={
                        <Background
                          className={styles.sectionImagesImage}
                          src={wellbeing3Image.url}
                        />
                      }
                    />
                  </div>
                  <div className={styles.sectionContent}>
                    <ul className={styles.sectionContentNav} aria-hidden={true}>
                      <li>
                        <AnchorLink
                          className={`${styles.button} ${styles.isActive}`}
                          href="#wellbeing"
                        >
                          <span className={styles.buttonText}>Well-being</span>
                        </AnchorLink>
                      </li>
                      <li>
                        <AnchorLink className={styles.button} href="#longevity">
                          <span className={styles.buttonText}>Longevity</span>
                        </AnchorLink>
                      </li>
                      <li>
                        <AnchorLink
                          className={styles.button}
                          href="#solidarity"
                        >
                          <span className={styles.buttonText}>Solidarity</span>
                        </AnchorLink>
                      </li>
                    </ul>
                    <div className={styles.sectionContentInner}>
                      <h2 className={styles.sectionTitle}>Well-being</h2>
                      <div className={styles.sectionContentInnerText}>
                        <div className="user-content-athena">
                          <p>
                            How do we infuse purpose into our lives? You can buy
                            all the tinctures, charcoal-infused beverages, and
                            spa retreats that you want, but if you don't have
                            meaning, you won't have well-being.
                          </p>
                          <p>
                            Well-being comes from a sense of connection to
                            ourselves, to our community, and to each other.
                            Right now, capitalism and the money economy are
                            based off symbolic transactions, not human
                            connections. An economy that values well-being
                            searches for and rewards actions that bring us an
                            abundance of happiness and joy. It is an ecosystem
                            of symbiotic connections from the self to the
                            community. Time spent on others is time regained on
                            yourself, and a well-being economy gives people a
                            sense of purpose. Currently, important jobs that
                            deeply benefit the well-being of members in a
                            society, such as caring for children or rebuilding
                            neighborhoods, aren't being recognized by a
                            money-based economy.
                          </p>
                        </div>
                      </div>
                      <div className={styles.mobileImage}>
                        <Fader
                          image1={
                            <Image url={wellbeing1Image.url} sizes="100vw" />
                          }
                          image2={
                            <Image url={wellbeing2Image.url} sizes="100vw" />
                          }
                        />
                      </div>
                      <div className={styles.sectionContentInnerText}>
                        <h3 className="mb-3 text-center a-h-3">The Model</h3>
                        <div className="user-content-athena">
                          <p>
                            Creating time-issued "service credits" would value
                            people's skills and put them to work in exchanging
                            with those around them. This results in a
                            reward-based system in which neighborhoods (people
                            and communities) swap time for services they truly
                            need and that benefit the entire community. Putting
                            value on the things money can't buy puts emphasis on
                            a system larger than yourself, empowering everyone
                            to feel useful (and emphasizing that human
                            connection is life). The feeling of making a
                            difference in someone's life is more valuable than
                            anything. This idea of service exchange is not new
                            for many cultures, not now or even historically, but
                            it's definitely something we've lost sight of in the
                            rat race of modern western life, where everything
                            has taken on a monetary price.
                          </p>
                        </div>
                      </div>
                      <div className={styles.mobileImage}>
                        <Image url={wellbeing3Image.url} sizes="100vw" />
                      </div>
                    </div>
                  </div>
                </Section>
                <Section id="longevity" className={styles.section}>
                  <div className={styles.sectionImages}>
                    <ImageSidebar
                      image1={
                        <Background
                          className={styles.sectionImagesImage}
                          src={longevity1Image.url}
                        />
                      }
                      image2={
                        <Background
                          className={styles.sectionImagesImage}
                          src={longevity2Image.url}
                        />
                      }
                      image3={
                        <Background
                          className={styles.sectionImagesImage}
                          src={longevity3Image.url}
                        />
                      }
                    />
                  </div>
                  <div className={styles.sectionContent}>
                    <ul className={styles.sectionContentNav} aria-hidden={true}>
                      <li>
                        <AnchorLink className={styles.button} href="#wellbeing">
                          <span className={styles.buttonText}>Well-being</span>
                        </AnchorLink>
                      </li>
                      <li>
                        <AnchorLink
                          className={`${styles.button} ${styles.isActive}`}
                          href="#longevity"
                        >
                          <span className={styles.buttonText}>Longevity</span>
                        </AnchorLink>
                      </li>
                      <li>
                        <AnchorLink
                          className={styles.button}
                          href="#solidarity"
                        >
                          <span className={styles.buttonText}>Solidarity</span>
                        </AnchorLink>
                      </li>
                    </ul>
                    <div className={styles.sectionContentInner}>
                      <h2 className={styles.sectionTitle}>Longevity</h2>
                      <div className={styles.sectionContentInnerText}>
                        <div className="user-content-athena">
                          <p>
                            The desire for "something more" and "something new"
                            has fueled our economy. For many, craftsmanship and
                            maintenance have given way to fast-fashion and
                            instant gratification. Obviously, we're experiencing
                            the limitations of this. And business is
                            course-correcting, though it's hard to say if the
                            moves are fast enough. So what can we do now? We can
                            shift our thinking away from acquiring and consuming
                            to maintaining and regenerating.
                          </p>
                          <p>
                            In an economy that values longevity, we would create
                            things that last a long time. A symbol of success
                            would be to not buy new things. A maker's or
                            manufacturer's relationship with a good wouldn't end
                            once it's shipped to the consumer. They would
                            continue to have an ongoing relationship with the
                            goods they introduce to the market by playing a
                            craftsman role in the repair and maintenance of
                            their products. New value would be placed on a local
                            repair person who can maintain without replacing.
                          </p>
                        </div>
                      </div>
                      <div className={styles.mobileImage}>
                        <Fader
                          image1={
                            <Image url={longevity1Image.url} sizes="100vw" />
                          }
                          image2={
                            <Image url={longevity2Image.url} sizes="100vw" />
                          }
                        />
                      </div>
                      <div className={styles.sectionContentInnerText}>
                        <h3 className="mb-3 text-center a-h-3">The Model</h3>
                        <div className="user-content-athena">
                          <p>
                            A system based on stewardship, not ownership. The
                            things we have would have value beyond just our
                            ownership of them. How would we treat the items we
                            use if we didn't own them, but were just their
                            current stewards? If you were borrowing a neighbor's
                            tool, how would you treat it? If we viewed ourselves
                            as stewards of the homes we live in for future
                            generations rather than owners, would you invest in
                            a trendy fast-fashion light fixture or maintain the
                            legacy craftsman fixtures that are original to the
                            home?
                          </p>
                          <p>
                            There are already a number of services that allow
                            you share your stuff. In fact, Zeus Jones won a
                            contest in our early years at SXSW that was rooted
                            in the idea of community pooling. But sharing feels
                            unsexy and, uh, a little granola. So how do we show
                            people the value of sharing and cooperation? Both
                            the connections we'd forge but also the long-term
                            impact they'd create—if it was repositioned as
                            something that was desirable and aspirational? Uber
                            normalized ride-sharing, commercialized it, and made
                            it a cool default. How can we do the same, but root
                            the exchange not in monetary compensation, but a
                            continued ethos of cooperation and giving?
                          </p>
                        </div>
                      </div>
                      <div className={styles.mobileImage}>
                        <Image url={longevity3Image.url} sizes="100vw" />
                      </div>
                    </div>
                  </div>
                </Section>
                <Section id="solidarity" className={styles.section}>
                  <div className={styles.sectionImages}>
                    <ImageSidebar
                      image1={
                        <Background
                          className={styles.sectionImagesImage}
                          src={solidarity1Image.url}
                        />
                      }
                      image2={
                        <Background
                          className={styles.sectionImagesImage}
                          src={solidarity2Image.url}
                        />
                      }
                      image3={
                        <Background
                          className={styles.sectionImagesImage}
                          src={solidarity3Image.url}
                        />
                      }
                    />
                  </div>
                  <div className={styles.sectionContent}>
                    <ul className={styles.sectionContentNav} aria-hidden={true}>
                      <li>
                        <AnchorLink className={styles.button} href="#wellbeing">
                          <span className={styles.buttonText}>Well-being</span>
                        </AnchorLink>
                      </li>
                      <li>
                        <AnchorLink className={styles.button} href="#longevity">
                          <span className={styles.buttonText}>Longevity</span>
                        </AnchorLink>
                      </li>
                      <li>
                        <AnchorLink
                          className={`${styles.button} ${styles.isActive}`}
                          href="#solidarity"
                        >
                          <span className={styles.buttonText}>Solidarity</span>
                        </AnchorLink>
                      </li>
                    </ul>
                    <div className={styles.sectionContentInner}>
                      <h2 className={styles.sectionTitle}>Solidarity</h2>
                      <div className={styles.sectionContentInnerText}>
                        <div className="user-content-athena">
                          <p>
                            An economy that values solidarity prioritizes
                            listening, understanding, and a unified ambition.
                            This economy seeks to nurture and reward those who
                            foster reciprocity, altruism, and love. This means
                            we need to prioritize shared values and perhaps an
                            ever-changing ownership structure—the common good
                            and the environment must be prioritized over
                            self-interest and the agency of the individual. With
                            these values at hand, materialism and mindless
                            consumerism will give way to a culture that grants
                            priority to family and personal relationships, new
                            voices and perspectives, and cooperation and respect
                            for the natural world.
                          </p>
                        </div>
                      </div>
                      <div className={styles.mobileImage}>
                        <Fader
                          image1={
                            <Image url={solidarity1Image.url} sizes="100vw" />
                          }
                          image2={
                            <Image url={solidarity2Image.url} sizes="100vw" />
                          }
                        />
                      </div>
                      <div className={styles.sectionContentInnerText}>
                        <h3 className="mb-3 text-center a-h-3">The Model</h3>
                        <div className="user-content-athena">
                          <p>
                            We need more intermediary enterprises that act as
                            both collectives and idea clearinghouses. Yes,
                            they're gate-keepers, but living in a "fake news"
                            world, we can all see the value in badging and
                            double-checking. This is an idea that plays off of
                            what tech philosopher Jaron Lanier has popularized
                            and coined "Mids." These are groups of people who
                            have shared values, shared perspectives, and a
                            shared ambition. In his vision of Mids, the point is
                            about gaining bargaining power over your data. But
                            the idea can be extended in many ways. These Mids
                            can essentially be a brand that operates as an idea
                            and movement clearinghouse: a platform for
                            cooperation and solidarity against a unified
                            ambition. The premise plays on a simple principle:
                            the bigger and louder your collective, the larger
                            your bargaining power becomes. In an era of
                            monopolization and gigantic conglomerates, we need
                            solidarity. The benefits would be numerous: Mids can
                            operate as idea exchanges (not driven by algorithms,
                            monetary profit, or a sense of opposition) and act
                            as collaborative way-finding rooted in action. It's
                            true that social movements on many platforms create
                            awareness—but they've also become a haven for toxic
                            or misleading ideas. Mids could be one way we get
                            beyond the fallacy of cancel culture, social
                            media-based signaling, and identity performance.
                            Instead we should create large collectives not
                            rooted in algorithms, but based on tangible action
                            toward a shared ambition.
                          </p>
                          <p>
                            At the same time, broader societal ills — runaway
                            consumer culture, stagnant social and economic
                            mobility, seemingly irrevocable cultural rifts — are
                            evidence of how solidarity-starved we've become.
                            We've been sold a narrative that each of us is a
                            movie star, deserving of every luxury and whim we
                            could possibly want — and, in exchange, we'd just
                            have to surrender our connections to each other,
                            whether through a workers' union, community identity
                            or social movement. We'd make every moment about me,
                            no matter how profane it might look. We'd remove
                            others from the script, except to make our own story
                            richer. But, looking around us today, did we
                            actually get anything out of the deal?
                          </p>
                          <p>
                            In a solidarity model, we recognize that the movie
                            is not about "me," but "us." We protect the progress
                            of our neighbors, families, and coworkers, perhaps
                            at our own expense or inconvenience. We'd recognize
                            the growth of solidarity and progress over months
                            and years, not by daily accumulations or trades.
                            Yes, this is nothing new — extreme inequality in the
                            late 1800s drove social and labor reforms of the
                            1920s and beyond — but it can't help but feel like
                            we're at the top of the rollercoaster again, waiting
                            for everything to drop. And the rebuilding out of
                            those crises — more aligned to social guardrails, a
                            safety net and protecting each other when in danger
                            — will inspire us to be less callous in our
                            relationships.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Section>
              </div>
            </div>

            <div className="wrapper">
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet">
                  <Image
                    url={endImage.url}
                    height={endImage.height}
                    width={endImage.width}
                  />
                </div>
              </div>
            </div>

            <div className={`${styles.copySection} ${styles.copySectionLast}`}>
              <div className="wrapper">
                <div className="columns is-tablet is-centered">
                  <div className="column is-10-tablet is-8-desktop">
                    <h2 className="mb-4 text-center a-h-2">How it Starts</h2>
                    <div className="user-content-athena">
                      <p>
                        Don’t cut up your credit cards just yet. Changing our
                        value systems won’t happen overnight, or even in a
                        generation. But adding more alternate-value transactions
                        into your daily life is a good place to start. Find a
                        way to express your desire for longevity, well-being or
                        solidarity—in your family, community or workplace. If
                        those values don’t sing to you, define something that
                        does. Capitalism is just a habit—and like any habit, it
                        feels impossible to break. But small changes, repeated
                        over time, form a new one. And from there, the sky’s the
                        limit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
          </div>
        </div>
      </article>
      <PreFooter />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      issues: categories(group: "athenaIssues", orderBy: "dateCreated asc") {
        id
      }
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      headerImage: asset(id: 20211) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      headerVideo: asset(id: 20238) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
          mimeType
        }
      }
      endImage: asset(id: 20210) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      intro1Image: asset(id: 20213) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      intro2Image: asset(id: 20214) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      intro3Image: asset(id: 20215) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      intro4Image: asset(id: 20216) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      longevity1Image: asset(id: 20220) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      longevity2Image: asset(id: 20221) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      longevity3Image: asset(id: 20222) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      solidarity1Image: asset(id: 20223) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      solidarity2Image: asset(id: 20224) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      solidarity3Image: asset(id: 20225) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      wellbeing1Image: asset(id: 20226) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      wellbeing2Image: asset(id: 20227) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      wellbeing3Image: asset(id: 20228) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`
